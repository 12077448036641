import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeesList, addFees, updateFee, deleteFee } from '../../../../../config/redux/inventory';

import { CardContainer } from '../../../../Reusables/BodyAndBars';

import TableFunctions from './components/TableFunctions';

function Fees() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const feesList = useSelector((state) => state.inventory && state.inventory.feesList);
  const reload = useSelector((state) => state.inventory && state.inventory.reload);

  const initialState = { name: '', price: 500};
  const headerList = [
    { id: 'name', width: '400px', type: 'text' },
    { id: 'price', width: '100px', type: 'number' },
  ];

  useEffect(() => {
    dispatch(getFeesList({ method: 'get', url: `fees`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [reload]);

  function getData() {
    dispatch(getFeesList({ method: 'get', url: `otherfees`, token: currentUser.userToken }));
  }

  function upload(data) {
    dispatch(addFees({ method: 'post', url: `fees/insert`, token: currentUser.userToken, data }));
  }

  function handleUpdate(id, data) {
    dispatch(updateFee({ method: 'post', url: `fees/${id}`, token: currentUser.userToken, data }));
  }

  function handleDelete(id) {
    dispatch(deleteFee({ method: 'delete', url: `fees/${id}`, token: currentUser.userToken }));
  }

  return (
    <CardContainer width={'100%'}>
      <TableFunctions initialState={initialState} headerName={'Other Fees'} headerList={headerList} tableWidth="auto" itemList={feesList} getData={getData} upload={upload} handleDelete={handleDelete} handleUpdate={handleUpdate} />
    </CardContainer>
  );
}

export default Fees;
