import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setPatientPage, setPatientId, setUserPage, setUserId, setChartPage, setDisableDate, setDateFrom, setDateTo, setDoctorFilter } from '../../../../../config/redux/settings';
import { updateChartStatus, getAllCharts } from '../../../../../config/redux/chart';
import { getDataList } from '../../../../../config/redux/dataList';

import { MainBar, TitlesContainer } from '../../../../Reusables/BodyAndBars';
import { IconsContainer, SaveIcon, CancelIcon, FilterIconWhite, RefreshIconWhite, GirlIcon, BoyIcon } from '../../../../Reusables/Icons';
import { ButtonPrimary, ButtonSuccess } from '../../../../Reusables/Buttons';
import {
  HeaderTitle,
  ChartInfoBarHeader,
  ChartInfoBar,
  ChartInfoContainer,
  ChartInfoLabelHeader,
  ChartInfoLabel,
  PatientAvatarContainer,
  PatientAvatar,
  PatientAvatarHeader,
  ChartInfoLabelEditable,
  ChartInfoLabelEditableText,
  SelectInputStatus,
  SelectInputOption,
  ViewChartButtonContainer,
  HideOnMobile,
  TopIconContainer,
} from '../Charts.styled';

import FilterModule from './FilterModule';

export const getAge = (dob) => {
  let dateNow = moment();
  let dateOfBirth = moment(dob);
  let years = moment.duration(dateNow.diff(dateOfBirth)).years();
  let months = moment.duration(dateNow.diff(dateOfBirth)).months();
  let days = moment.duration(dateNow.diff(dateOfBirth)).days();
  return `${years}y - ${months}m - ${days}d`;
};

function ChartList() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const refreshRate = useSelector((state) => state.settings && state.settings.refreshRate);
  const chartStatus = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.chartStatus);
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);
  const dateFrom = useSelector((state) => state.settings && state.settings.dateFrom);
  const dateTo = useSelector((state) => state.settings && state.settings.dateTo);
  const chartData = useSelector((state) => state.chart && state.chart.chartData);
  const chartList = useSelector((state) => state.chart && state.chart.allChartsData);
  const clinicSite = useSelector((state) => state.settings && state.settings.clinicSite);
  const statusList = useSelector((state) => state.settings && state.settings.statusList);
  const doctorFilter = useSelector((state) => state.settings && state.settings.doctorFilter);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [itemEdit, setItemEdit] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [hideFilter, setHideFilter] = useState(true);
  const [showDoctor, setShowDoctor] = useState(true);
  const [triggerRefreshCount, setTriggerRefreshCount] = useState(0);

  useEffect(() => {
    dispatch(getDataList({ method: 'get', url: 'datalist/all', token: currentUser.userToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusColor = (status) => {
    let resultColor;
    chartStatus &&
      chartStatus.forEach((a) => {
        if (a.status === status) {
          resultColor = a.color;
        }
      });
    return resultColor;
  };

  const handleEdit = (status, index) => {
    setIsEditStatus(true);
    setNewStatus(status);
    setItemEdit(index);
  };

  const handeSave = (id, status) => {
    dispatch(updateChartStatus({ method: 'post', url: `chart/updatestatus/${id}`, token: currentUser.userToken, data: { status } }));
    setIsEditStatus(false);
  };

  const handleViewPatient = (id) => {
    dispatch(setPatientId(id));
    dispatch(setPatientPage('ViewPatient'));
    dispatch(setCurrentPage('Patients'));
  };

  const handleViewDoctor = (id) => {
    dispatch(setUserId(id));
    dispatch(setUserPage('userProfilePage'));
    dispatch(setCurrentPage('Users'));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTriggerRefreshCount(triggerRefreshCount + 1);
    }, refreshRate);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [triggerRefreshCount]);

  const handleToday = (bol) => {
    if (bol) {
      dispatch(setDisableDate(true));
      dispatch(setDateFrom(moment().startOf('day')));
      dispatch(setDateTo(moment().endOf('day')));
    } else {
      dispatch(setDisableDate(false));
    }
  };

  useEffect(() => {
    dispatch(getAllCharts({ method: 'post', url: 'chartlist', token: currentUser.userToken, data: { dateFrom, dateTo, statusList, doctorFilter, clinicSite } }));
    // eslint-disable-next-line
  }, [chartData, statusList, dateFrom, dateTo, doctorFilter, triggerRefreshCount]);

  return (
    <>
      <MainBar noColumn={true}>
        <TitlesContainer>
          <HeaderTitle>Chart List </HeaderTitle>
        </TitlesContainer>

        <IconsContainer>
          <TopIconContainer title="filter" onClick={() => setHideFilter(!hideFilter)}>
            <FilterIconWhite />
          </TopIconContainer>
          <TopIconContainer title="refresh" onClick={() => setTriggerRefreshCount(triggerRefreshCount + 1)}>
            <RefreshIconWhite />
          </TopIconContainer>

          <ButtonSuccess
            onClick={() => {
              dispatch(setCurrentPage('Patients'));
            }}
          >
            Search Patient
          </ButtonSuccess>
        </IconsContainer>
      </MainBar>
      <FilterModule hideFilter={hideFilter} handleToday={handleToday} setShowDoctor={setShowDoctor} showDoctor={showDoctor} setDoctorFilter={setDoctorFilter} doctorFilter={doctorFilter} />
      <ChartInfoBarHeader>
        <ChartInfoContainer>
          <ChartInfoLabelHeader width={'60px'} label={'TIMEIN'}>
            Date
          </ChartInfoLabelHeader>
          <ChartInfoLabelHeader width={'70px'} label={'TIMEIN'}>
            Time In
          </ChartInfoLabelHeader>
          <PatientAvatarHeader width={'50px'} label={'IMAGE'}>
            Img
          </PatientAvatarHeader>
          <ChartInfoLabelHeader width={'150px'}>Patient's Name</ChartInfoLabelHeader>
          <ChartInfoLabelHeader width={'122px'} label={'PREFFEREDNAME'}>
            Preferred Name
          </ChartInfoLabelHeader>
          <ChartInfoLabelHeader width={'70px'} label={'SEX'}>
            Sex
          </ChartInfoLabelHeader>
          <ChartInfoLabelHeader width={'130px'} label={'AGE'}>
            Age
          </ChartInfoLabelHeader>
          <ChartInfoLabelHeader width={'150px'} label={'DOCTOR'} showDoctor={showDoctor}>
            Doctor
          </ChartInfoLabelHeader>
          <ChartInfoLabelHeader width={'180px'} label={'STATUS'}>
            Status
          </ChartInfoLabelHeader>
        </ChartInfoContainer>
      </ChartInfoBarHeader>
      {chartList &&
        chartList.map((chart, index) => (
          <ChartInfoBar key={index} status={`${getStatusColor(chart.status)}`}>
            <ChartInfoContainer>
              <ChartInfoLabel width={'60px'} label={'TIMEIN'}>
                {moment(chart.createdAt).format('MMM DD')}
              </ChartInfoLabel>
              <ChartInfoLabel width={'70px'} label={'TIMEIN'}>
                {moment(chart.createdAt).format('h:mm a')}
              </ChartInfoLabel>
              <PatientAvatarContainer width={'50px'} label={'IMAGE'}>
                {chart.patientData[0].personalInfo.image ? <PatientAvatar src={chart.patientData[0].personalInfo.image} /> : <>{chart.patientData[0].personalInfo.sex === 'Female' ? <GirlIcon /> : <BoyIcon />}</>}
              </PatientAvatarContainer>
              <ChartInfoLabel width={'150px'} pointer={true} onClick={() => handleViewPatient(chart.patientId)}>
                {chart.patientData[0].personalInfo.firstName} {chart.patientData[0].personalInfo.lastName}
              </ChartInfoLabel>
              <ChartInfoLabel width={'122px'} label={'PREFFEREDNAME'}>
                {chart.patientData[0].personalInfo.preferredName}
              </ChartInfoLabel>
              <ChartInfoLabel width={'70px'} label={'SEX'}>
                {chart.patientData[0].personalInfo.sex}
              </ChartInfoLabel>
              <ChartInfoLabel width={'130px'} label={'AGE'}>
                {getAge(chart.patientData[0].personalInfo.dateOfBirth)}
              </ChartInfoLabel>
              <ChartInfoLabel width={'150px'} label={'DOCTOR'} pointer={true} showDoctor={showDoctor} onClick={() => handleViewDoctor(chart.doctorId)}>
                {doctorList && // eslint-disable-next-line
                  doctorList.map((doctor) => {
                    if (doctor._id === chart.doctorId) return `${doctor.personalInfo.firstName} ${doctor.personalInfo.lastName}`;
                  })}
              </ChartInfoLabel>
              <ChartInfoLabelEditable width={'180px'} label={'STATUS'}>
                {isEditStatus && itemEdit === index ? (
                  <SelectInputStatus value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
                    {chartStatus &&
                      chartStatus.map((a, index) => {
                        return (
                          <SelectInputOption key={index} value={`${a.status}`}>
                            {a.status}
                          </SelectInputOption>
                        );
                      })}
                  </SelectInputStatus>
                ) : (
                  <ChartInfoLabelEditableText
                    onClick={() => {
                      handleEdit(chart.status, index);
                    }}
                  >
                    {chart.status}
                  </ChartInfoLabelEditableText>
                )}
                {isEditStatus && itemEdit === index && (
                  <>
                    <HideOnMobile onClick={() => handeSave(chart._id, newStatus)}>
                      <SaveIcon />
                    </HideOnMobile>
                    <HideOnMobile
                      onClick={() => {
                        setIsEditStatus(false);
                      }}
                    >
                      <CancelIcon />
                    </HideOnMobile>
                  </>
                )}
              </ChartInfoLabelEditable>
            </ChartInfoContainer>
            <IconsContainer>
              <ViewChartButtonContainer>
                <ButtonPrimary
                  onClick={() => {
                    dispatch(setChartPage('ViewChart'));
                    dispatch(setPatientId(chart.patientId));
                  }}
                >
                  View Chart
                </ButtonPrimary>
              </ViewChartButtonContainer>
            </IconsContainer>
          </ChartInfoBar>
        ))}
    </>
  );
}

export default ChartList;
