import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFramesList, insertManyFramesList, updateFrames, deleteFrames } from '../../../../../config/redux/inventory';

import { CardContainer } from '../../../../Reusables/BodyAndBars';

import TableFunctions from './components/TableFunctions';

function Frames() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const framesList = useSelector((state) => state.inventory && state.inventory.framesList);
  const reload = useSelector((state) => state.inventory && state.inventory.reload);

  const initialState = { itemNumber: '', brand: '', cost: 100.00, price: 130.00, minQty: 10, onHand: 100, status: 'available' };
  const headerList = [
    { id: 'itemNumber', width: '250px', type: 'text' },
    { id: 'brand', width: '250px', type: 'text' },
    { id: 'cost', width: '100px', type: 'number' },
    { id: 'price', width: '100px', type: 'number' },
    { id: 'status', width: '100px', type: 'text' },
  ];

  useEffect(() => {
    dispatch(getFramesList({ method: 'get', url: `frames`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [reload]);

  function getData() {
    dispatch(getFramesList({ method: 'get', url: `frames`, token: currentUser.userToken }));
  }

  function upload(data) {
    dispatch(insertManyFramesList({ method: 'post', url: `frames/insert`, token: currentUser.userToken, data }));
  }

  function handleUpdate(id, data) {
    dispatch(updateFrames({ method: 'post', url: `frames/${id}`, token: currentUser.userToken, data }));
  }

  function handleDelete(id) {
    dispatch(deleteFrames({ method: 'delete', url: `frames/${id}`, token: currentUser.userToken }));
  }

  return (
    <CardContainer width={'100%'}>
      <TableFunctions initialState={initialState} headerName={'Frames'} headerList={headerList} tableWidth="1100px" itemList={framesList} getData={getData} upload={upload} handleDelete={handleDelete} handleUpdate={handleUpdate} />
    </CardContainer>
  );
}

export default Frames;
