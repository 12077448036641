import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMedicineList, insertManyMedicines, updateMedicine, deleteMedicine } from '../../../../../config/redux/inventory';

import { CardContainer } from '../../../../Reusables/BodyAndBars';

import TableFunctions from './components/TableFunctions';

function Medicines() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const medicineList = useSelector((state) => state.inventory && state.inventory.medicineList);
  const reload = useSelector((state) => state.inventory && state.inventory.reload);

  const initialState = { name: '', generic: '', preparation: '', dosage: '', expDate: '2025-01-01', cost: '', price: '', minQty: '', onHand: '' };
  const headerList = [
    { id: 'name', width: '320px', type: 'text' },
    { id: 'generic', width: '300px', type: 'text' },
    { id: 'preparation', width: '200px', type: 'text' },
    { id: 'dosage', width: '200px', type: 'text' },
    { id: 'expDate', width: '120px', type: 'date' },
    { id: 'cost', width: '100px', type: 'number' },
    { id: 'price', width: '100px', type: 'number' },
    { id: 'minQty', width: '100px', type: 'number' },
    { id: 'onHand', width: '100px', type: 'number' },
  ];

  useEffect(() => {
    dispatch(getMedicineList({ method: 'get', url: `medicine`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [reload]);

  function getData() {
    dispatch(getMedicineList({ method: 'get', url: `medicine`, token: currentUser.userToken }));
  }

  function upload(data) {
    dispatch(insertManyMedicines({ method: 'post', url: `medicine/insert`, token: currentUser.userToken, data }));
  }

  function handleUpdate(id, data) {
    dispatch(updateMedicine({ method: 'post', url: `medicine/${id}`, token: currentUser.userToken, data }));
  }

  function handleDelete(id) {
    dispatch(deleteMedicine({ method: 'delete', url: `medicine/${id}`, token: currentUser.userToken }));
  }

  return (
    <CardContainer width={'100%'}>
      <TableFunctions initialState={initialState} headerName={'Medicine'} headerList={headerList} tableWidth="1750px" itemList={medicineList} getData={getData} upload={upload} handleDelete={handleDelete} handleUpdate={handleUpdate} />
    </CardContainer>
  );
}

export default Medicines;
