import styled from 'styled-components/macro';

export const ChartMainContainer = styled.div`
  position: relative;
  z-index: 0;
`;

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.CardFontLight};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};
  font-size: 1rem;
  padding: 10px 10px 0px 10px;
`;

export const PatientsChartPage = styled.div`
  display: flex;
`;

export const PatientsHistoryContainer = styled.div`
  border-radius: 6px 6px 0px 0px;
  width: 16%;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  margin: 5px;
  min-height: 750px;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const HamburgerIconContainer = styled.div`
  margin: 3px 3px 0px 0px;
  display: none;

  @media (max-width: 992px) {
    display: inline-block;
  }
`;

export const PatientsHistoryBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 5px 5px 0px 0px;
  min-height: 30px;
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
`;

export const PatientsHistoryBody = styled.div`
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 5px;
  width: 100%;
`;

export const AppointmentHistoryContainer = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  width: 100%;
  padding: 8px 20px;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 5px solid ${(props) => (props.active ? 'orange' : props.theme.MainNav)};
  margin: 1px;
  font-size: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const PatientsChartContainer = styled.div`
  border-radius: 6px 6px 0px 0px;
  width: 80%;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: #ffefcf;
  margin: 5px;

  @media (max-width: 992px) {
    width: 98%;
  }
`;

export const PatientsChartBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 5px 5px 0px 0px;
  min-height: 30px;
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
`;

export const PatientsChartBody = styled.div`
  background-color: #ffefcf;
  ${({ theme }) => theme.MainBackground};
  padding: 5px;
  width: 100%;
`;

export const ChartPersonalInfo = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const PatientImage = styled.img`
  width: 220px;
  height: auto;

  @media (max-width: 576px) {
    margin: 0px auto 10px auto;
  }
`;

export const PatientChartDetails = styled.div`
  width: 100%;
  padding: 0px 5px 0px 5px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px;
  padding: 5px;
  width: 50%;
  box-shadow: 2px 2px 3px #ccc;
  border: 1px solid ${({ theme }) => theme.CardBorder};

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const InfoLabel = styled.div`
  color: ${({ theme }) => theme.FontColor};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.8rem')};
  padding: ${(props) => (props.padding ? props.padding : '2px')};
  width: ${(props) => (props.width ? props.width : 'auto')};
`;

export const InfoData = styled.div`
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.FontColor};
  border: ${(props) => (props.border ? props.border : `1px solid ${props.theme.CardBorder}`)};
  background-color: white;
  padding: 5px;
  min-height: 24px;
  padding-left: 10px;
  font-size: 0.8rem;
  white-space: pre-wrap;

  &:hover {
    color: ${(props) => (props.pointer ? props.theme.MainNav : props.theme.FontColor)};
  }
`;

export const ChartCardTitle = styled.div`
  padding: 7px 0px 0px 10px;
  font-size: 1rem;
`;

export const InfoInput = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 8px;
  width: ${(props) => (props.width ? props.width : '100%')};
  padding-left: 6px;
  font-size: 1rem;
`;

export const ChartSeparator = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  min-height: 25px;
  background-color: ${({ theme }) => theme.CardBackground};
  margin-bottom: 10px;
`;

export const SelectInputStatus = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 1px 3px;
  font-size: 0.8rem;
  margin-right: 3px;
  width: 70%;
`;

export const SelectInputOption = styled.option`
  color: ${({ theme }) => theme.FontColor};
  padding: 1px 3px;
  font-size: 0.8rem;
  margin-right: 3px;
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
`;
export const EditInputContainer = styled.div`
  display: flex;
`;

export const SelectDateInput = styled.input``;

export const LoadingContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

export const Loading = styled.div`
  background-color: rgba(100, 100, 100, 0.3);
  position: absolute;
  z-index: 10;
  border: none;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChartCardInputContainer = styled.div`
  background-color: ${(props) => (props.background ? props.background : props.theme.CardBackground)};
  margin: 2px;
  padding: 5px;
  width: ${(props) => (props.width ? props.width : '24%')};
  display: ${(props) => (props.display ? props.display : 'block')};
  box-shadow: 2px 2px 3px #ccc;
  border: 1px solid ${({ theme }) => theme.CardBorder};

  @media (max-width: 576px) {
    width: 100%;
    overflow-x: scroll;
  }
`;

export const ChartCardBar = styled.div`
  background-color: ${({ theme }) => theme.CardHeader};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  min-height: 39px;
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
`;

export const ChartCardBody = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
`;

export const ChartCardBodyAccordion = styled.div`
  overflow: hidden;
  transition: all 1s ease-in-out;
  max-height: ${(props) => (props.isMinimized ? '0px' : '1500px')};
`;

export const InfoInputTextArea = styled.textarea`
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  font-family: 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
`;

export const SelectCardContainer = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10;
  border: none;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const SelectCardNav = styled.nav`
  margin-top: 150px;
  display: block;
  width: 300px;
  position: absolute;
  background-color: white;
  z-index: 10;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  border-radius: 5px;
  box-shadow: 2px 2px 10px #888888;
  font-size: 1rem;
`;

export const SelectCardNavButtons = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.MainNav};
  width: 100%;
  padding: 8px 15px;
  color: ${({ theme }) => theme.CardFontLight};
  border-bottom: 1px solid ${({ theme }) => theme.CardBorder};
  border-left: 1px solid ${({ theme }) => theme.CardBorder};

  &:hover {
    background-color: ${({ theme }) => theme.MainNavHover};
  }
`;

export const SelectCardCancel = styled.div`
  display: flex;
  width: 35px;
`;

export const SelectCardCancelText = styled.div`
  padding: 5px 0px 3px 10px;
`;

export const DiagnosisDataContainer = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.CardBorder};
  margin: 2px;
  padding: 2px 10px;
  width: 100%;
  display: flex;
  height: auto;
  justify-content: space-between;
  box-shadow: 2px 2px 3px #ccc;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const DiagnosisAddDataContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px;
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 576px) {
    width: 100%;
  }
`;
export const SelectInputDiagnosis = styled.select`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 5px 3px;
  font-size: 1rem;
  margin-right: 3px;
  width: 350px;
`;

export const DiagnosisInfoContainer = styled.div`
  display: flex;
  padding-top: 5px;
`;

export const ProcedureAddDataContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const SearchInputField = styled.input`
  color: ${({ theme }) => theme.FontColor};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  background-color: ${({ theme }) => theme.MainBackground};
  padding: 5px 3px 5px 15px;
  font-size: 1rem;
  margin-right: 3px;
  width: 350px;
`;

export const SearchResultContainer = styled.div`
  background-color: ${({ theme }) => theme.MainBackground};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  margin-top: 10px;
  width: 100%;
  height: auto;
  padding-bottom: 2px;
`;

export const SearchResultButtonContainer = styled.div`
  display: flex;
`;

export const SearchResult = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  width: auto;
  padding: 5px;
  font-size: 0.8rem;
  margin: 2px 2px 0px 2px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: ${({ theme }) => theme.CardHeader};
  }
`;

export const ChartCardSubContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  border: 1px solid ${({ theme }) => theme.CardBorder};
  width: 100%;
  padding: 5px 10px;
  margin-top: 5px;
  box-shadow: 2px 2px 3px #ccc;
`;

export const ChartCardSubtitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.CardBackground};
  font-size: 0.8rem;
  font-weight: bold;
  padding-bottom: 5px;
  color: ${({ theme }) => theme.CardFont};
  border-bottom: ${(props) => (props.bottomBorder ? props.bottomBorder : `1px solid ${props.theme.CardBorder}`)};
`;

export const TwoColumnCardContainer = styled.div`
  display: flex;
`;
export const TwoColumnCard = styled.div`
  width: 50%;
  padding: 5px;
`;

export const ProcedureAddOtherDataContainer = styled.div`
  background-color: ${({ theme }) => theme.CardBackground};
  margin: 2px;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid green;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const AddOtherProcedureInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DiagnosisButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 600px) {
    margin-top: 15px;
    justify-content: center;
  }
`;

export const DiagnosisContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const EyesButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const SearchMedicineContainer = styled.div`
  display: flex;
`;

export const SearchResultHeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.TopBar};
  display: flex;
`;
export const SearchResultHeader = styled.div`
  width: ${(props) => props.width};
  text-align: center;
  background-color: ${({ theme }) => theme.TopBar};
  color: ${({ theme }) => theme.CardFontLight};
  font-size: 0.8rem;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: ${(props) => (props.last ? 'none' : `1px solid ${props.theme.CardFontLight}`)};
`;

export const SearchResultListContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 5px 0px;

  &:hover {
    background-color: #ffefcf;
  }
`;
export const SearchResultItem = styled.div`
  width: ${(props) => props.width};
  text-align: ${(props) => (props.type === 'text' ? 'left' : 'center')};
  font-size: 0.8rem;
  padding: 5px;
  border-right: 1px solid ${(props) => props.theme.TopBar};
  border-bottom: 1px solid ${(props) => props.theme.TopBar};
  color: ${(props) => (props.shortage ? 'red' : 'black')};
  font-weight: ${(props) => (props.shortage ? 'bold' : 'normal')};
`;

export const SearchResultItemAction = styled.div`
  cursor: pointer;
  width: ${(props) => props.width};
  text-align: ${(props) => (props.type === 'text' ? 'left' : 'center')};
  font-size: 0.8rem;
  padding: 5px;
  border-right: ${(props) => (props.last ? 'none' : `1px solid ${props.theme.TopBar}`)};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.MainNav};
    color: ${(props) => props.theme.CardFontLight};
  }
`;

export const EditMedicineContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.CardBorder};
  margin: 5px auto;
  padding: 10px;
  border-radius: 5px;
  width: 50%;
  background-color: #ffefcf;
`;

export const PrescriptionContainer = styled.div`
  border-bottom: 1px solid lightgray;
  margin: 0px 10px 5px 15px;
  padding-bottom: 5px;
`;

export const RxImage = styled.img`
  width: 65px;
`;

export const AddOtherMedicineContainer = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.CardBorder};
  padding: 10px;
  border-radius: 5px;
`;
export const AddOtherMedicineButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
`;
export const AddOtherMedicineHeader = styled.div`
  background-color: ${({ theme }) => theme.TopBar};
  text-align: center;
  color: white;
  font-size: 0.8rem;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const CertifyText = styled.div`
  font-size: 0.8rem;
  text-align: center;
  padding: 5px 20px;
`;

export const MedCertDataHeader = styled.div`
  margin-top: 30px;
  margin-left: 10px;
  font-size: 0.8rem;
  font-weight: 700;
`;

export const MedCertDataContent = styled.div`
  margin: 10px 20px 10px 40px;
  font-size: 0.8rem;
`;

export const LabRequestContent = styled.div`
  margin: 10px 20px;
  font-size: 0.8rem;
`;

export const NoChartFoundContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NoChartFound = styled.div`
  width: 400px;
  height: 200px;
  background-color: ${({ theme }) => theme.CardBackground};
  border-radius: 5px;
  box-shadow: 2px 2px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const NoChartFoundText = styled.div`
  padding-bottom: 20px;
  font-size: 2rem;
  font-weight: 700;
`;

export const NoChartButtonContainer = styled.div``;

export const Owner = styled.span`
  font-size: 0.8rem;
  font-style: italic;
  margin-left: 30px;
`;

export const DisplayContainer = styled.div`
  padding: 5px;
  margin: 5px 15px;
`;

export const InlineInputContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const InlineInput = styled.input`
  margin: 2px;
  padding: 10px;
  height: 30px;
  width: ${(props) => (props.width ? props.width : '80px')};
`;

export const InlineMultiLineInput = styled.textarea`
  margin: 2px;
  padding: 10px;
  width: 600px;
`;

export const InlineSelect = styled.select`
  margin: 2px;
  padding: 10px;
  height: 40px;
  width: 110px;
`;

export const LabRequestViewContainer = styled.div`
  background-color: white;
  padding: 10px;
`;

export const AddOthersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & div {
    display: flex;
    flex-direction: row;
    padding: 5 px;

    & div {
      padding: 10px;
    }
  }
`;
