import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import rx from '../../../../../../../images/rx.jpg';
import moment from 'moment';

import { addCardtoChart, updateCardInChart } from '../../../../../../../config/redux/chart';
import { searchMedicine } from '../../../../../../../config/redux/inventory';

import {
  ChartCardInputContainer,
  ChartCardTitle,
  ChartCardBar,
  ChartCardBody,
  InfoLabel,
  InfoInput,
  ChartSeparator,
  ChartCardBodyAccordion,
  SearchMedicineContainer,
  SearchResultContainer,
  SearchResultHeaderContainer,
  SearchResultHeader,
  SearchResultListContainer,
  SearchResultItem,
  EditMedicineContainer,
  PrescriptionContainer,
  RxImage,
  AddOtherMedicineContainer,
  AddOtherMedicineButtonContainer,
  AddOtherMedicineHeader,
  InfoInputTextArea,
  Owner,
  LabRequestViewContainer,
} from '../ViewChart.styled';
import { IconsContainer, EditIcon, MaximizeIcon, MinimizeIcon, PrintIcon } from '../../../../../../Reusables/Icons';
import { ButtonPrimary, ButtonDanger, ButtonSuccess } from '../../../../../../Reusables/Buttons';
import { PrintPageContainer, PrintBody, PrintTitle } from '../../../../../../Reusables/PrintTemplates/PrintTemplate.styled';

import PrintHeader from '../../../../../../Reusables/PrintTemplates/PrintHeader';
import PrintPatientsInfo from '../../../../../../Reusables/PrintTemplates/PatientsInfo';
import PhysicianDetails from '../../../../../../Reusables/PrintTemplates/PhysicianDetails';
import PrintFooter from '../../../../../../Reusables/PrintTemplates/PrintFooter';

function PrescriptionCard({ isNewCard, setAddNewCard, cardId, setHideAddCardButton, cardIndex, cardData, doctorId, patientInfo, date, owner, addedDate }) {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const medicineList = useSelector((state) => state.inventory && state.inventory.medicineList);
  const doctorList = useSelector((state) => state.dataList && state.dataList.dataList && state.dataList.dataList.doctorList);
  const assignedDoctor = doctorList.filter((doctor, index) => doctor._id === doctorId)[0];

  const [cardIsEdit, setCardIsEdit] = useState(false);
  const [editMedicine, setEditMedicine] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [name, setName] = useState('');
  const [generic, setGeneric] = useState('');
  const [dateAndTime, setDateAndTime] = useState(moment());
  const [addOtherMedicine, setAddOtherMedicine] = useState(false);
  const [otherMedicineData, setOtherMedicineData] = useState({ _id: 'otherMedicine', name: '', generic: '', preparation: '', dosage: '' });
  const [isAddNote, setIsAddNote] = useState(false);
  const [noteData, setNoteData] = useState('');
  const [isPrint, setIsPrint] = useState(false);

  let Header = [
    { name: 'Medicine Name', id: 'name', type: 'text', width: '35%' },
    { name: 'Generic Name', id: 'generic', type: 'text', width: '35%' },
    { name: 'ExpDate', id: 'expDate', type: 'date', width: '10%' },
    { name: 'Cost', id: 'cost', type: 'number', width: '10%' },
    { name: 'On Hand', id: 'onHand', type: 'number', width: '10%' },
  ];

  function handleAddCard() {
    setCardIsEdit(false);
    setAddNewCard('');
    setEditMedicine([]);
    dispatch(
      addCardtoChart({
        method: 'post',
        url: `chart/addCard/${cardId}`,
        token: currentUser.userToken,
        data: { cardType: 'prescription_card', cardData: editMedicine, addedByName: `${currentUser.userFirstName.charAt(0).toUpperCase()}. ${currentUser.userLastName}` },
      })
    );
  }

  function handleUpdateCard() {
    setCardIsEdit(false);
    setHideAddCardButton(false);
    dispatch(updateCardInChart({ method: 'post', url: `chart/updateCard/${cardId}?index=${cardIndex}`, token: currentUser.userToken, data: { cardType: 'prescription_card', cardData: editMedicine } }));
  }

  function handleCancel() {
    setEditMedicine({});
    setCardIsEdit(false);
    setAddNewCard('');
    setName('');
    setGeneric('');
    setHideAddCardButton(false);
  }

  function handleEdit() {
    setEditMedicine(cardData);
    setCardIsEdit(true);
    setHideAddCardButton(true);
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(searchMedicine({ method: 'get', url: `medicine/search?name=${name}&generic=${generic}`, token: currentUser.userToken }));
    }, 700);

    return () => clearTimeout(delay);
    // eslint-disable-next-line
  }, [name, generic]);

  function handleAddMedicine(medicine) {
    let tempMedicine = { id: medicine._id, name: medicine.name, generic: medicine.generic, medDosage: medicine.dosage, preparation: medicine.preparation, quantity: '', dosage: '', frequency: '' };
    setEditMedicine(editMedicine === [] ? tempMedicine : [...editMedicine, tempMedicine]);
  }

  function handleAddNotes() {
    let tempNote = { id: 'note', note: noteData };
    setEditMedicine(editMedicine === [] ? tempNote : [...editMedicine, tempNote]);
    setIsAddNote(false);
  }

  function handleUpdateMedicine(index, name, value) {
    setEditMedicine(editMedicine.map((item, i) => (index === i ? { ...item, [name]: value } : item)));
  }

  function handleRemoveMedicine(index) {
    setEditMedicine(editMedicine.filter((med, i) => i !== index));
  }

  function handleChangeAddOtherMedicine(name, value) {
    setOtherMedicineData({ ...otherMedicineData, [name]: value });
  }

  function handleAddOtherMedicine() {
    handleAddMedicine(otherMedicineData);
    setOtherMedicineData({ _id: 'otherMedicine', name: '', generic: '', preparation: '', dosage: '' });
    setAddOtherMedicine(false);
  }

  function handleBeforeGetContent() {
    setDateAndTime(moment());
    setIsPrint(true);
    return Promise.resolve();
  }

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => handleBeforeGetContent(),
    content: () => componentRef.current,
    documentTitle: `${patientInfo && patientInfo.lastName}_${patientInfo && patientInfo.firstName}_prescription`,
    onAfterPrint: () => setIsPrint(false),
  });

  return (
    <>
      <ChartCardBar>
        <ChartCardTitle>
          Prescription Card{' '}
          {!isNewCard && (
            <Owner>
              Added by: <b>{owner}</b> on {moment(addedDate).format('yyy-MM-DD')}
            </Owner>
          )}
        </ChartCardTitle>
        <IconsContainer>
          {isMinimized ? (
            <div title={'maximize'} onClick={() => setIsMinimized(false)}>
              <MaximizeIcon />
            </div>
          ) : (
            <>
              {!(cardIsEdit || isNewCard) && roles && roles[userRole] && roles[userRole]['prescription_card'] && roles[userRole]['prescription_card']['update'] && (
                <>
                  <div title="print" onClick={handlePrint}>
                    <PrintIcon />
                  </div>
                  <div title={'edit'} onClick={handleEdit}>
                    <EditIcon />
                  </div>
                </>
              )}
              <div title={'minimize'} onClick={() => setIsMinimized(true)}>
                <MinimizeIcon />
              </div>
            </>
          )}
        </IconsContainer>
      </ChartCardBar>
      <ChartCardBodyAccordion isMinimized={isMinimized}>
        <ChartCardBody>
          {cardIsEdit || isNewCard ? (
            <>
              <ChartCardInputContainer width={'100%'}>
                {editMedicine !== [] &&
                  editMedicine.map((medicine, index) => {
                    return (
                      <div key={index}>
                        {medicine.id === 'note' ? (
                          <EditMedicineContainer>
                            <InfoLabel>Note: </InfoLabel>
                            <InfoInputTextArea rows={'6'} width={'100%'} value={medicine.note} name={'note'} onChange={(e) => handleUpdateMedicine(index, e.target.name, e.target.value)} />
                            <ButtonDanger onClick={() => handleRemoveMedicine(index)}>Remove</ButtonDanger>
                          </EditMedicineContainer>
                        ) : (
                          <EditMedicineContainer>
                            <InfoLabel>
                              <b>{`${medicine.name} (${medicine.generic})`}</b>
                              {` ${medicine.medDosage} ${medicine.preparation}`}
                            </InfoLabel>
                            <InfoLabel>Quantity: </InfoLabel>
                            <InfoInput width={'100%'} value={medicine.quantity} name={'quantity'} onChange={(e) => handleUpdateMedicine(index, e.target.name, e.target.value)} />
                            <InfoLabel>Dosage: </InfoLabel>
                            <InfoInput width={'100%'} value={medicine.dosage} name={'dosage'} onChange={(e) => handleUpdateMedicine(index, e.target.name, e.target.value)} />
                            <InfoLabel>Frequency: </InfoLabel>
                            <InfoInput width={'100%'} value={medicine.frequency} name={'frequency'} onChange={(e) => handleUpdateMedicine(index, e.target.name, e.target.value)} />
                            <br />
                            <ButtonDanger onClick={() => handleRemoveMedicine(index)}>Remove</ButtonDanger>
                          </EditMedicineContainer>
                        )}
                      </div>
                    );
                  })}
              </ChartCardInputContainer>
              {addOtherMedicine ? (
                <ChartCardInputContainer width={'100%'}>
                  <AddOtherMedicineContainer>
                    <AddOtherMedicineHeader>Add Other Medicine</AddOtherMedicineHeader>
                    <InfoLabel>Medicine: </InfoLabel>
                    <InfoInput value={otherMedicineData.name} name={'name'} onChange={(e) => handleChangeAddOtherMedicine(e.target.name, e.target.value)} />
                    <InfoLabel>Generic: </InfoLabel>
                    <InfoInput value={otherMedicineData.generic} name={'generic'} onChange={(e) => handleChangeAddOtherMedicine(e.target.name, e.target.value)} />
                    <InfoLabel>Preparation: </InfoLabel>
                    <InfoInput value={otherMedicineData.preparation} name={'preparation'} onChange={(e) => handleChangeAddOtherMedicine(e.target.name, e.target.value)} />
                    <InfoLabel>Dosage: </InfoLabel>
                    <InfoInput value={otherMedicineData.dosage} name={'dosage'} onChange={(e) => handleChangeAddOtherMedicine(e.target.name, e.target.value)} />
                    <AddOtherMedicineButtonContainer>
                      <ButtonDanger onClick={() => setAddOtherMedicine(false)}>Cancel</ButtonDanger>
                      <ButtonPrimary onClick={() => handleAddOtherMedicine()}>Add Medicine</ButtonPrimary>
                    </AddOtherMedicineButtonContainer>
                  </AddOtherMedicineContainer>
                </ChartCardInputContainer>
              ) : (
                <>
                  {isAddNote ? (
                    <ChartCardInputContainer width={'100%'}>
                      <InfoInputTextArea rows={'4'} autoFocus onChange={(e) => setNoteData(e.target.value)} />
                      <ButtonPrimary onClick={handleAddNotes}>Add Note</ButtonPrimary>
                    </ChartCardInputContainer>
                  ) : (
                    <ChartCardInputContainer width={'100%'} hidden={false}>
                      <SearchMedicineContainer>
                        <InfoLabel width={'15%'} padding={'5px 0px 0px 5px'}>
                          Search Medicine
                        </InfoLabel>
                        <InfoInput width={'35%'} value={name} onChange={(e) => setName(e.target.value)} />
                        <InfoLabel width={'15%'} padding={'5px 0px 0px 15px'}>
                          Search Generic
                        </InfoLabel>
                        <InfoInput width={'35%'} value={generic} onChange={(e) => setGeneric(e.target.value)} />
                      </SearchMedicineContainer>
                      <SearchResultContainer>
                        <SearchResultHeaderContainer>
                          {Header.map((item, index) => {
                            return (
                              <SearchResultHeader key={index} width={item.width}>
                                {item.name}
                              </SearchResultHeader>
                            );
                          })}
                        </SearchResultHeaderContainer>

                        {medicineList &&
                          medicineList.map((medicine, index) => {
                            return (
                              <SearchResultListContainer key={index} onClick={() => handleAddMedicine(medicine)}>
                                {Header.map((item, i) => {
                                  return (
                                    <SearchResultItem key={i} width={item.width} type={item.type} shortage={item.id === 'onHand' && medicine.onHand < medicine.minQty}>
                                      {item.type === 'date' ? moment(medicine[item.id]).format('MMM yyyy') : medicine[item.id]}
                                    </SearchResultItem>
                                  );
                                })}
                              </SearchResultListContainer>
                            );
                          })}
                      </SearchResultContainer>
                    </ChartCardInputContainer>
                  )}
                </>
              )}
            </>
          ) : (
            <ChartCardInputContainer width={'100%'}>
              {isPrint ? (
                <PrintPageContainer ref={componentRef}>
                  <PrintHeader />
                  <PrintTitle>PRESCRIPTION</PrintTitle>
                  <PrintPatientsInfo patientInfo={patientInfo} date={date} />
                  <PrintBody height={'510px'}>
                    <RxImage src={rx} />
                    {cardData &&
                      cardData.map((medicine, index) => {
                        return (
                          <PrescriptionContainer key={index}>
                            {medicine.id === 'note' ? (
                              <>
                                <InfoLabel fontSize={'0.7rem'}>{medicine.note}</InfoLabel>
                              </>
                            ) : (
                              <>
                                <InfoLabel fontSize={'0.7rem'}>{` # ${medicine.quantity} `}</InfoLabel>
                                <InfoLabel fontSize={'0.8rem'}>
                                  <b>{`${medicine.name} (${medicine.generic}) `}</b> {`${medicine.medDosage} ${medicine.preparation}`}
                                </InfoLabel>
                                <InfoLabel fontSize={'0.7rem'}>{`Sig: ${medicine.dosage} ${medicine.frequency}`}</InfoLabel>
                              </>
                            )}
                          </PrescriptionContainer>
                        );
                      })}
                  </PrintBody>
                  <PhysicianDetails
                    name={`${assignedDoctor.personalInfo.namePrefix ? assignedDoctor.personalInfo.namePrefix : ''} ${assignedDoctor.personalInfo.firstName} ${assignedDoctor.personalInfo.middleName.charAt(0)}. ${
                      assignedDoctor.personalInfo.lastName
                    } ${assignedDoctor.personalInfo.nameSuffix ? assignedDoctor.personalInfo.nameSuffix : ''}`}
                    prc={assignedDoctor.employmentRecord.prcNumber}
                    ptr={assignedDoctor.employmentRecord.ptrNumber}
                  />
                  <PrintFooter dateAndTime={dateAndTime} />
                </PrintPageContainer>
              ) : (
                <LabRequestViewContainer>
                  {cardData &&
                    cardData.map((medicine, index) => {
                      return (
                        <PrescriptionContainer key={index}>
                          {medicine.id === 'note' ? (
                            <>
                              <InfoLabel fontSize={'0.7rem'}>{medicine.note}</InfoLabel>
                            </>
                          ) : (
                            <>
                              <InfoLabel fontSize={'0.7rem'}>{` # ${medicine.quantity} `}</InfoLabel>
                              <InfoLabel fontSize={'0.8rem'}>
                                <b>{`${medicine.name} (${medicine.generic}) `}</b> {`${medicine.medDosage} ${medicine.preparation}`}
                              </InfoLabel>
                              <InfoLabel fontSize={'0.7rem'}>{`Sig: ${medicine.dosage} ${medicine.frequency}`}</InfoLabel>
                            </>
                          )}
                        </PrescriptionContainer>
                      );
                    })}
                </LabRequestViewContainer>
              )}
            </ChartCardInputContainer>
          )}
        </ChartCardBody>
      </ChartCardBodyAccordion>
      {(isNewCard || cardIsEdit) && (
        <ChartSeparator>
          <ButtonSuccess onClick={() => setAddOtherMedicine(true)}>Add Other Medicine</ButtonSuccess>
          <ButtonDanger onClick={handleCancel}>Cancel</ButtonDanger>
          <ButtonPrimary onClick={() => (isNewCard ? handleAddCard() : handleUpdateCard())}>Save</ButtonPrimary>
          {isAddNote ? <ButtonSuccess onClick={() => setIsAddNote(false)}>Add Medicine</ButtonSuccess> : <ButtonSuccess onClick={() => setIsAddNote(true)}>Add Notes</ButtonSuccess>}
        </ChartSeparator>
      )}
    </>
  );
}

export default PrescriptionCard;
