import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getChartsByPatientId, setStatus } from '../../../../../../config/redux/chart';

import { MainBar, TitlesContainer, HamburgerNavContainer, HamburgerNav } from '../../../../../Reusables/BodyAndBars';
import { IconsContainer, HamburgerIconWhite } from '../../../../../Reusables/Icons';
import { ButtonPrimary, ButtonSuccess } from '../../../../../Reusables/Buttons';
import { setChartPage, setCurrentPage, setPatientPage } from '../../../../../../config/redux/settings';

import {
  HeaderTitle,
  PatientsChartPage,
  PatientsHistoryContainer,
  HamburgerIconContainer,
  PatientsHistoryBar,
  PatientsHistoryBody,
  AppointmentHistoryContainer,
  PatientsChartContainer,
  ChartSeparator,
  LoadingContainer,
  NoChartFoundContainer,
  NoChartFound,
  NoChartFoundText,
  NoChartButtonContainer,
} from './ViewChart.styled';

import ChartDetails from './components/ChartDetails';
import SelectCard from './components/SelectCard';
import NurseOptoCard from './components/NurseOptoCard';
import ProcedureCard from './components/ProcedureCard';
import OphthalmologyCard from './components/OphthalmologyCard';
import LabRequestCard from './components/LabRequestCard';
import PrescriptionCard from './components/PrescriptionCard';
import BillingCard from './components/BillingCard/BillingCard';
import RemarksCard from './components/Remarks';
import MedCertCard from './components/MedCertCard';
import PageLoading from '../../../../../Loading';

function ViewChart() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user && state.user.currentUser);
  const refreshRate = useSelector((state) => state.settings && state.settings.refreshRate);
  const patientId = useSelector((state) => state.settings && state.settings.patientId);
  const dataList = useSelector((state) => state.dataList && state.dataList.dataList);
  const userRole = useSelector((state) => state.user && state.user.currentUser && state.user.currentUser.userRole);
  const roles = useSelector((state) => state.settings && state.settings.rolesSettings && state.settings.rolesSettings);
  const patientsChartList = useSelector((state) => state.chart && state.chart.patientsChartList);
  const status = useSelector((state) => state.chart && state.chart.status);
  const triggerPatientChartList = useSelector((state) => state.chart && state.chart.triggerPatientChartList);
  const [selectedAppointmentIndex, setSelectedAppointmentIndex] = useState(0);
  const [openSelectCard, setOpenSelectCard] = useState(false);
  const [addNewCard, setAddNewCard] = useState('');
  const [hideAddCardButton, setHideAddCardButton] = useState(false);
  const [triggerRefreshCount, setTriggerRefreshCount] = useState(0);
  const [isHide, setIsHide] = useState(true);
  const [itemIsEdit, setItemIsEdit] = useState(false);

  useEffect(() => {
    dispatch(setStatus('loading'));
    dispatch(getChartsByPatientId({ method: 'get', url: `chart/${patientId}`, token: currentUser.userToken }));
    // eslint-disable-next-line
  }, [triggerPatientChartList]);

  useEffect(() => {
    dispatch(getChartsByPatientId({ method: 'get', url: `chart/${patientId}`, token: currentUser.userToken }));
    setTimeout(() => {
      !itemIsEdit && !addNewCard && setTriggerRefreshCount(triggerRefreshCount + 1);
    }, refreshRate);

    // eslint-disable-next-line
  }, [triggerRefreshCount]);

  const disableRefresh = (status) => {
    setItemIsEdit(status);
  };

  return (
    <>
      {openSelectCard && <SelectCard setOpenSelectCard={setOpenSelectCard} setAddNewCard={setAddNewCard} />}
      {patientsChartList.length === 0 && status !== 'loading' && (
        <NoChartFoundContainer>
          <NoChartFound>
            <NoChartFoundText>No Chart Found</NoChartFoundText>
            <NoChartButtonContainer>
              <ButtonPrimary
                onClick={() => {
                  dispatch(setCurrentPage('Charts'));
                  dispatch(setChartPage('ChartList'));
                }}
              >
                Go to Chart List
              </ButtonPrimary>
              <ButtonSuccess
                onClick={() => {
                  dispatch(setCurrentPage('Patients'));
                  dispatch(setPatientPage('PatientList'));
                }}
              >
                Go to Patient List
              </ButtonSuccess>
            </NoChartButtonContainer>
          </NoChartFound>
        </NoChartFoundContainer>
      )}
      <MainBar noColumn={true}>
        <TitlesContainer>
          <HamburgerIconContainer
            onClick={() => {
              setIsHide(!isHide);
            }}
          >
            <HamburgerIconWhite />
          </HamburgerIconContainer>
          <HamburgerNavContainer maxHeight={isHide ? '0px' : '500px'} top={'97px'}>
            {patientsChartList &&
              patientsChartList.map((appointment, index) => {
                return (
                  <HamburgerNav
                    key={index}
                    active={selectedAppointmentIndex === index && true}
                    onClick={() => {
                      setSelectedAppointmentIndex(index);
                      setIsHide(true);
                    }}
                  >
                    {moment(appointment.appointmentDate).format('MMM DD, yyyy')}
                  </HamburgerNav>
                );
              })}
          </HamburgerNavContainer>
          <HeaderTitle>Patient's Chart</HeaderTitle>
        </TitlesContainer>
        <IconsContainer>
          <ButtonSuccess
            onClick={() => {
              dispatch(setChartPage('ChartList'));
            }}
          >
            Back to Chart List
          </ButtonSuccess>
        </IconsContainer>
      </MainBar>

      <PatientsChartPage>
        <PatientsHistoryContainer>
          <PatientsHistoryBar>Appointment History</PatientsHistoryBar>
          <PatientsHistoryBody>
            {patientsChartList &&
              patientsChartList.map((appointment, index) => {
                return (
                  <AppointmentHistoryContainer key={index} active={selectedAppointmentIndex === index && true} onClick={() => setSelectedAppointmentIndex(index)}>
                    {moment(appointment.appointmentDate).format('MMM DD, yyyy')}
                  </AppointmentHistoryContainer>
                );
              })}
          </PatientsHistoryBody>
        </PatientsHistoryContainer>
        <PatientsChartContainer>
          {status === 'loading' && (
            <LoadingContainer>
              <PageLoading />
            </LoadingContainer>
          )}

          <ChartDetails selectedAppointmentIndex={selectedAppointmentIndex} patientId={patientId} />
          <ChartSeparator>
            {addNewCard === '' && !hideAddCardButton && patientsChartList.length !== 0 && (
              <ButtonPrimary
                onClick={() => {
                  setOpenSelectCard(true);
                }}
              >
                Add Card to Chart
              </ButtonPrimary>
            )}
          </ChartSeparator>
          {addNewCard === 'nurse_card' && roles && roles[userRole] && roles[userRole]['nurse_card'] && roles[userRole]['nurse_card']['create'] && (
            <NurseOptoCard
              title={'Nurse Card'}
              cardType={'nurse_card'}
              isNewCard={true}
              setHideAddCardButton={setHideAddCardButton}
              dataList={dataList.nurseCardData}
              setAddNewCard={setAddNewCard}
              cardId={patientsChartList[selectedAppointmentIndex]._id}
            />
          )}
          {addNewCard === 'opto_card' && roles && roles[userRole] && roles[userRole]['opto_card'] && roles[userRole]['opto_card']['create'] && (
            <NurseOptoCard
              title={'Optomitrist Card'}
              cardType={'opto_card'}
              isNewCard={true}
              setHideAddCardButton={setHideAddCardButton}
              dataList={dataList.optoCardData}
              setAddNewCard={setAddNewCard}
              cardId={patientsChartList[selectedAppointmentIndex]._id}
            />
          )}
          {addNewCard === 'refrac_card' && roles && roles[userRole] && roles[userRole]['refrac_card'] && roles[userRole]['refrac_card']['create'] && (
            <NurseOptoCard
              title={'Refraction Card'}
              cardType={'refrac_card'}
              isNewCard={true}
              setHideAddCardButton={setHideAddCardButton}
              dataList={dataList.refractionCard}
              setAddNewCard={setAddNewCard}
              cardId={patientsChartList[selectedAppointmentIndex]._id}
            />
          )}
          {addNewCard === 'lab_request_card' && roles && roles[userRole] && roles[userRole]['lab_request_card'] && roles[userRole]['lab_request_card']['create'] && (
            <LabRequestCard
              isNewCard={true}
              patientInfo={patientsChartList[0].patientData[0].personalInfo}
              date={patientsChartList[selectedAppointmentIndex].appointmentDate}
              setHideAddCardButton={setHideAddCardButton}
              setAddNewCard={setAddNewCard}
              cardId={patientsChartList[selectedAppointmentIndex]._id}
              doctorId={patientsChartList[selectedAppointmentIndex].doctorId}
            />
          )}
          {addNewCard === 'procedure_card' && roles && roles[userRole] && roles[userRole]['procedure_card'] && roles[userRole]['procedure_card']['create'] && (
            <ProcedureCard isNewCard={true} options={dataList.procedureList} setHideAddCardButton={setHideAddCardButton} setAddNewCard={setAddNewCard} cardId={patientsChartList[selectedAppointmentIndex]._id} />
          )}
          {addNewCard === 'ophtha_card' && roles && roles[userRole] && roles[userRole]['ophtha_card'] && roles[userRole]['ophtha_card']['create'] && (
            <OphthalmologyCard isNewCard={true} options={dataList.diagnosisList} setHideAddCardButton={setHideAddCardButton} setAddNewCard={setAddNewCard} cardId={patientsChartList[selectedAppointmentIndex]._id} />
          )}
          {addNewCard === 'prescription_card' && roles && roles[userRole] && roles[userRole]['prescription_card'] && roles[userRole]['prescription_card']['create'] && (
            <PrescriptionCard isNewCard={true} setHideAddCardButton={setHideAddCardButton} setAddNewCard={setAddNewCard} cardId={patientsChartList[selectedAppointmentIndex]._id} />
          )}
          {addNewCard === 'billing_card' && roles && roles[userRole] && roles[userRole]['billing_card'] && roles[userRole]['billing_card']['create'] && (
            <BillingCard isNewCard={true} setHideAddCardButton={setHideAddCardButton} setAddNewCard={setAddNewCard} cardId={patientsChartList[selectedAppointmentIndex]._id} />
          )}
          {addNewCard === 'remarks_card' && roles && roles[userRole] && roles[userRole]['remarks_card'] && roles[userRole]['remarks_card']['create'] && (
            <RemarksCard isNewCard={true} setHideAddCardButton={setHideAddCardButton} setAddNewCard={setAddNewCard} cardId={patientsChartList[selectedAppointmentIndex]._id} />
          )}
          {addNewCard === 'med_cert_card' && roles && roles[userRole] && roles[userRole]['med_cert_card'] && roles[userRole]['med_cert_card']['create'] && (
            <MedCertCard
              isNewCard={true}
              cardId={patientsChartList[selectedAppointmentIndex]._id}
              doctorId={patientsChartList[selectedAppointmentIndex].doctorId}
              patientInfo={patientsChartList[0].patientData[0].personalInfo}
              date={patientsChartList[selectedAppointmentIndex].appointmentDate}
              setAddNewCard={setAddNewCard}
              setHideAddCardButton={setHideAddCardButton}
              disableRefresh={disableRefresh}
              remarks={
                patientsChartList[selectedAppointmentIndex].cards.filter((card) => card.cardType === 'remarks_card')[0] && patientsChartList[selectedAppointmentIndex].cards.filter((card) => card.cardType === 'remarks_card')[0].cardData
                  ? patientsChartList[selectedAppointmentIndex].cards.filter((card) => card.cardType === 'remarks_card')[0].cardData
                  : ''
              }
              diagnosis={
                patientsChartList[selectedAppointmentIndex].cards.filter((card) => card.cardType === 'ophtha_card')[0] && patientsChartList[selectedAppointmentIndex].cards.filter((card) => card.cardType === 'ophtha_card')[0].cardData.diagnosisList
                  ? patientsChartList[selectedAppointmentIndex].cards.filter((card) => card.cardType === 'ophtha_card')[0].cardData.diagnosisList
                  : ''
              }
            />
          )}

          {patientsChartList[selectedAppointmentIndex] &&
            patientsChartList[selectedAppointmentIndex].cards &&
            patientsChartList[selectedAppointmentIndex].cards.map((card, index) => {
              return (
                <div key={index}>
                  {card.cardType === 'nurse_card' && roles && roles[userRole] && roles[userRole]['nurse_card'] && roles[userRole]['nurse_card']['read'] && (
                    <NurseOptoCard
                      key={index}
                      title={'Nurse Card'}
                      isNewCard={false}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      dataList={card.cardData}
                      cardIndex={index}
                      cardType={'nurse_card'}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}
                  {card.cardType === 'opto_card' && roles && roles[userRole] && roles[userRole]['opto_card'] && roles[userRole]['opto_card']['read'] && (
                    <NurseOptoCard
                      key={index}
                      title={'Optometrist Card'}
                      isNewCard={false}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      dataList={card.cardData}
                      cardIndex={index}
                      cardType={'opto_card'}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}
                  {card.cardType === 'refrac_card' && roles && roles[userRole] && roles[userRole]['refrac_card'] && roles[userRole]['refrac_card']['read'] && (
                    <NurseOptoCard
                      key={index}
                      title={'Refraction Card'}
                      isNewCard={false}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      dataList={card.cardData}
                      cardIndex={index}
                      cardType={'refrac_card'}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}
                  {card.cardType === 'lab_request_card' && roles && roles[userRole] && roles[userRole]['lab_request_card'] && roles[userRole]['lab_request_card']['read'] && (
                    <LabRequestCard
                      key={index}
                      isNewCard={false}
                      doctorId={patientsChartList[selectedAppointmentIndex].doctorId}
                      patientInfo={patientsChartList[0].patientData[0].personalInfo}
                      date={patientsChartList[selectedAppointmentIndex].appointmentDate}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      cardData={card.cardData}
                      cardIndex={index}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}
                  {card.cardType === 'remarks_card' && roles && roles[userRole] && roles[userRole]['remarks_card'] && roles[userRole]['remarks_card']['read'] && (
                    <RemarksCard
                      key={index}
                      isNewCard={false}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      cardData={card.cardData}
                      cardIndex={index}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}

                  {card.cardType === 'procedure_card' && roles && roles[userRole] && roles[userRole]['procedure_card'] && roles[userRole]['procedure_card']['read'] && (
                    <ProcedureCard
                      key={index}
                      isNewCard={false}
                      options={dataList.procedureList}
                      setAddNewCard={setAddNewCard}
                      setHideAddCardButton={setHideAddCardButton}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      dataList={card.cardData}
                      cardIndex={index}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      patientInfo={patientsChartList[0].patientData[0].personalInfo}
                      date={patientsChartList[selectedAppointmentIndex].appointmentDate}
                      doctorId={patientsChartList[selectedAppointmentIndex].doctorId}
                      disableRefresh={disableRefresh}
                    />
                  )}
                  {card.cardType === 'ophtha_card' && roles && roles[userRole] && roles[userRole]['ophtha_card'] && roles[userRole]['ophtha_card']['read'] && (
                    <OphthalmologyCard
                      key={index}
                      isNewCard={false}
                      options={dataList.diagnosisList}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      dataList={card.cardData}
                      cardIndex={index}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}
                  {card.cardType === 'prescription_card' && roles && roles[userRole] && roles[userRole]['prescription_card'] && roles[userRole]['prescription_card']['read'] && (
                    <PrescriptionCard
                      key={index}
                      isNewCard={false}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      cardData={card.cardData}
                      cardIndex={index}
                      doctorId={patientsChartList[selectedAppointmentIndex].doctorId}
                      patientInfo={patientsChartList[0].patientData[0].personalInfo}
                      date={patientsChartList[selectedAppointmentIndex].appointmentDate}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}
                  {card.cardType === 'billing_card' && roles && roles[userRole] && roles[userRole]['billing_card'] && roles[userRole]['billing_card']['read'] && (
                    <BillingCard
                      key={index}
                      isNewCard={false}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      cardData={card.cardData}
                      cardIndex={index}
                      patientInfo={patientsChartList[0].patientData[0].personalInfo}
                      date={patientsChartList[selectedAppointmentIndex].appointmentDate}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}
                  {card.cardType === 'med_cert_card' && roles && roles[userRole] && roles[userRole]['billing_card'] && roles[userRole]['billing_card']['read'] && (
                    <MedCertCard
                      key={index}
                      isNewCard={false}
                      setHideAddCardButton={setHideAddCardButton}
                      setAddNewCard={setAddNewCard}
                      cardId={patientsChartList[selectedAppointmentIndex]._id}
                      dataList={card.cardData}
                      cardIndex={index}
                      patientInfo={patientsChartList[0].patientData[0].personalInfo}
                      date={patientsChartList[selectedAppointmentIndex].appointmentDate}
                      doctorId={patientsChartList[selectedAppointmentIndex].doctorId}
                      owner={card.addedByName}
                      addedDate={card.addedOn}
                      disableRefresh={disableRefresh}
                    />
                  )}
                </div>
              );
            })}
        </PatientsChartContainer>
      </PatientsChartPage>
    </>
  );
}

export default ViewChart;
